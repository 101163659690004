import { VNodeChild } from "vue";
export interface IResponse<DT> {
    success?: boolean;
    error?: string;
    message: string | string[];
    data?: DT;
}
export interface IPageResponse<DT> {
    currentPage: number
    total: number
    perPage: number
    lastPage: number
    data: DT
}

export interface BaseData {
    created_at: string;
    created_by: string;
    updated_at: string;
    updated_by: string;
    deleted: boolean;
}

export interface IFeature {
    "feature": {
        "FEATURE_BIGDATA": boolean|string
        "FEATURE_ACADEMY": boolean|string
        "FEATURE_ELEARNING": boolean|string
        "FEATURE_KPI": boolean|string
    }
}

export interface ISignInPayload {
    account_email: string;
    account_password: string;
}
export interface ICreatePasswordPayload {
    password: string;
    confirmPassword: string;
}
export interface IUpdatePasswordPayload extends ICreatePasswordPayload {
    oldPassword: string
}
export interface Roles {
	bigData: IRolePermission|null
	academy: IRolePermission|null
	eLearning: IRolePermission|null
    report:IRolePermission | null
}
interface IMenuPermission {
    [key: string]: boolean
    /* team: boolean;
    staff: boolean;
    report: boolean;
    athlete: boolean;
    setting: boolean;
    stadium: boolean;
    team_edit: boolean;
    staff_edit: boolean;
    competition: boolean;
    athlete_edit: boolean;
    setting_edit: boolean;
    stadium_edit: boolean;
    competition_edit: boolean;
    member_management: boolean;
    application_management: boolean;
    application_management_edit: boolean; */
}
export const EPermissionMenu = [
    'นักกีฬา',
    'บุคลากร',
    'ทีม/สโมสร',
    'สนามกีฬา',
    'รายการแข่งขัน',
    'ตั้งค่าระบบ',
    'ตั้งค่าองค์กร',
    "รายงาน"
 ] as const

 export const EPermissionMenuCheckList = [
    {key: 'นักกีฬา', athlete: false, athlete_edit: false, _: []},
    {key: 'บุคลากร', staff: false, staff_edit: false, _: []},
    {key: 'ทีม/สโมสร', team: false, team_edit: false, _: []},
    {key: 'สนามกีฬา', stadium: false, stadium_edit: false, _: []},
    {key: 'รายการแข่งขัน', competition: false, competition_edit: false, _: []},
    {key: 'ตั้งค่าระบบ', setting: false, setting_edit: false, _: []},
    {key: 'ตั้งค่าองค์กร', application_management: false, application_management_edit: false, _: []},
    {key: 'รายงาน', report_management: false, report_edit: false, _: []},
 ]

 export const EPermissionAcademy = [
		"ฝั่งผู้ใช้งาน",
		"รายชื่ออคาเดมี่",
		"ตั้งค่า",
		"กิจกรรมระบบ",
		"แดชบอร์ด",
		"อนุมัติใบอนุญาต",
		"ตรวจสอบเอกสาร",
 ];

 export const EPermissionAcademyCheckList = [
		{ key: "ฝั่งผู้ใช้งาน", user: false, user_edit: false, _: [] },
		{ key: "รายชื่ออคาเดมี่", academy: false, academy_edit: false, _: [] },
		{ key: "ตั้งค่า", setting: false, setting_edit: false, _: [] },
		{ key: "กิจกรรมระบบ", activity: false, activity_edit: false, _: [] },
		{ key: "แดชบอร์ด", dashboard: false, dashboard_edit: false, _: [] },
		{
			key: "อนุมัติใบอนุญาต",
			license_approves: false,
			license_approves_edit: false,
			_: [],
		},
		{
			key: "ตรวจสอบเอกสาร",
			document_approves: false,
			document_approves_edit: false,
			_: [],
		},
 ];

 export const EPermissionElearning = [
		"ฝั่งผู้ใช้งาน",
		"แดชบอร์ด",
		"สร้างหลักสูตร",
		"เปิดรอบหลักสูตร",
		"จัดการบุคลากร",
		"จัดการแชท",
		"จัดการหน้าเว็บ",
 ];

 export const EPermissionELearningCheckList = [
		{ key: "ฝั่งผู้ใช้งาน", user: false, user_edit: false, _: [] },
		{ key: "แดชบอร์ด", dashboard: false, dashboard_edit: false, _: [] },
		{
			key: "สร้างหลักสูตร",
			create_course: false,
			create_course_edit: false,
			_: [],
		},
		{ key: "เปิดรอบหลักสูตร", open_course: false, open_course_edit: false, _: [] },
		{
			key: "จัดการบุคลากร",
			account_setting: false,
			account_setting_edit: false,
			_: [],
		},
		{
			key: "จัดการแชท",
			chat_setting: false,
			chat_setting_edit: false,
			_: [],
		},
		{
			key: "จัดการหน้าเว็บ",
			web_setting: false,
			web_setting_edit: false,
			_: [],
		},
 ];


 export const EPermissionReportCheckList = [
    { key: "Competition", user: false, user_edit: false, _: [] },
    { key: "Academy", dashboard: false, dashboard_edit: false, _: [] },
    {
        key: "E-Learning",
        create_course: false,
        create_course_edit: false,
        _: [],
    },
   
];

export interface IPermission extends BaseData {
    roles: number
    permission_code: string;//'A_ADMIN' | 'E_ADMIN' | '',
    permission_name_th: string;
    permission_name_en: string;
    permission_menu: IMenuPermission
    id?: number;
    active?: boolean
}

export interface IRolePermission extends BaseData {
    id: number
    account_roles: number
    active: boolean
    permissions: IPermission
}

export interface IAccountRole {
    id: number;
    role_featured: 'BIGDATA' | 'ACADEMY' | 'ELEARNING' | 'KPI';
    role_loggedin: boolean;
    roles_permission: IRolePermission;
    token?: IAuthToken
}

export interface IAccount {
    account_id: number;
    account_email: string;
    account_roles: IAccountRole[];
}
export interface IAuthToken {
    access_token: string;
    token_type?: string;
    expires_in?: string;
}
export interface IUser {
    account: IAccount;
    token: IAuthToken;
}
export interface IAlertModal {
    visible: boolean
    type: "success"|"danger"
    message: string
}
export interface ICreatePwdDto {
    account_email: string
    token: string
    account_password: string
    confirm_password: string
}
export interface IUpdatePwdDto extends Omit<ICreatePwdDto, "token"|"account_email">{
    old_password: string
}
export interface IMemberAccount extends BaseData {
    id: number
    active: boolean
    role_loggedin: boolean
    accounts: {
        active: boolean
        account_id: number
        account_region_citizen: number
        account_region_current: number
        account_specifications: string
        account_email: string
        account_email_verified: boolean
        account_email_verified_token: string
        account_email_verified_expires: Date
        account_password_verified_token: string
        account_type: number
        account_subscription_login_at: Date
        account_subscription_logout_at: Date
        account_published: boolean
        account_informations: {
            id: number
            active: boolean
            account_information_code_personnel: string
            account_information_citizen_id: string
            account_information_passport_id: string
            account_information_type_personnel: string
            account_information_firstname_th: string
            account_information_lastname_th: string
            account_information_firstname_en: string
            account_information_lastname_en: string
            account_information_nickname: string
            account_information_blood_type: number | null
            account_information_dob: Date,
            account_information_religion: string
            account_information_race: string
            account_information_nationality: string
            account_information_phone: string
            account_information_gender: number
            account_information_height: number
            account_information_weight: number
            account_information_biography_th: string
            account_information_biography_en: string
            account_information_hand_skill: string
            account_information_feet_skill: string
        },
        roles: {
            id: number
            active: boolean
            role_featured: string
        },
        role_permission: IRolePermission
    }
}

export interface IPermissionPayload {
    permission_name_th: string;
    permission_name_en: string;
    permission_menu: IMenuPermission
    active: boolean
}

export interface MenuInfo {
    key: string;
    keyPath: string[];
    item: VNodeChild;
    domEvent: MouseEvent;
}